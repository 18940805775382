import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { About } from './pages/dashboard/About';
import { Home } from './pages/dashboard/Home';
import Login from './pages/auth/login';
import Navbar from './components/navbar/Navbar';
import AddJob from './pages/dashboard/jobs/add-job/addJob';
import { JobDetails } from './pages/dashboard/jobs/job-details/jobDetails';
import AddCandidate from './pages/dashboard/candidates/add-candidate/addCandidate';
import AddNewAdmin from './pages/dashboard/admins/add-new-admin/addNewAdmin';
import HiringManagers from './pages/dashboard/hiring-managers/hiringManagers';
import Signup from './pages/auth/signup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadData } from './redux/services/handleData/localStorage';
import AddHiringManagers from './pages/dashboard/hiring-managers/add-hiring-managers/addHiringManagers';
import { getTokenData } from './firebase';
import ForgotPassword from './pages/auth/forgotPassword';
import { JobAdmins } from './pages/dashboard/job-admins/jobAdmins';
import ResetPassword from './pages/auth/resetPassword';
import { InfoModal } from './components/molecules/modal/modal';
import noInternet from './assets/no-internet.svg';
import { Heading2, Para } from './components/atoms/typography/typography';
import { useSelector } from 'react-redux';
import {
  getAlertHeadingValue,
  getAlertParaValue,
  getAlertValue
} from './redux/states/ui/ui.services';
import { useDispatch } from 'react-redux';
import { ALERT_MODAL_OFF } from './redux/states/ui/ui.actions';
import OutOfService from './pages/outOfService';
if (process.env.REACT_APP_ENV != 'stage') {
  Sentry.init({
    dsn: 'https://043d6e19e521aacd333fd089b07e2c44@o4505577648029696.ingest.sentry.io/4505905971462144',
    environment: process.env.REACT_APP_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/]
      }),
      new Sentry.Replay()
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

const App: React.FC = () => {
  const alertState = useSelector(getAlertValue);
  const alertHeading = useSelector(getAlertHeadingValue);
  const alertPara = useSelector(getAlertParaValue);
  const dispatch = useDispatch();

  function handleAlertBtnClick() {
    dispatch({ type: ALERT_MODAL_OFF });
  }

  useEffect(() => {
    getTokenData();
  }, []);

  function routeList() {
    if (loadData('token')) {
      return (
        <Routes>
          <Route path="/" element={<OutOfService />} />
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/about" element={<About />} />
          <Route path="/add-job" element={<AddJob />} />
          <Route path="/job-details/:jobId" element={<JobDetails />} />
          <Route path="/add-candidate/:jobId" element={<AddCandidate />} />
          <Route path="/hiring-managers" element={<HiringManagers />} />
          <Route path="/add-hiring-managers" element={<AddHiringManagers />} />
          <Route path="/add-new-admin/:jobId" element={<AddNewAdmin />} />
          <Route path="/job-admins/:jobId" element={<JobAdmins />} />
          <Route path="*" element={<Navigate to="/" replace />}></Route>
        </Routes>
      );
    } else {
      return (
        <Routes>
          <Route path="/" element={<OutOfService />} />
          {/* <Route path="/" element={<Login />} /> */}
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="*" element={<Navigate to="/" replace />}></Route>
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      );
    }
  }
  return (
    <>
      <Navbar />
      {routeList()}
      <ToastContainer />
      <InfoModal active={alertState} onClose={handleAlertBtnClick}>
        <div className="text-center">
          <img src={noInternet} className="mb-3" />
          <Heading2 className="mb-2">{alertHeading}</Heading2>
          <Para textColor="var(--textGray)" className="mb-4">
            {alertPara}
          </Para>
        </div>
      </InfoModal>
    </>
  );
};

export default App;
