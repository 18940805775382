export enum ButtonType {
  BUTTON = 'button',
  SUBMIT = 'submit'
}

export enum InterviewStatusType {
  INVITED = 'INVITED',
  INPROGRESS = 'INPROGRESS',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED'
}

export enum LoaderType {
  TILE = 'TILE',
  CARD = 'CARD'
}

export enum InterviewUpdateType {
  REINVITE_INTERVIEW = 'REINVITE_INTERVIEW',
  RETRY_INTERVIEW = 'RETRY_INTERVIEW'
}
