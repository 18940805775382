import { CaretDown } from 'phosphor-react';
import React from 'react';

interface SelectInputProps {
  setData: (value: any) => void;
  labelText: string;
  optionsList?: any;
}

function SelectInput(prop: SelectInputProps) {
  const { setData, labelText, optionsList } = prop;
  return (
    <div className="position-relative">
      <label className="mb-1">{labelText}</label>
      <select
        className="form-select form-control"
        aria-label="Default select example"
        onChange={(e) => {
          setData(e.target.value);
        }}
        defaultValue={optionsList[0]}
      >
        {optionsList.map((optionValue: string, index: number) => (
          <option value={optionValue} key={index}>
            {optionValue}
          </option>
        ))}
      </select>
      <CaretDown size={18} className="select-arrow" />
    </div>
  );
}

export default SelectInput;
