const loadData = (key: string): any => {
  try {
    let data: any = localStorage.getItem(key);
    return JSON.parse(data);
  } catch (err) {
    return undefined;
  }
};

function saveData(key: string, data: any) {
  localStorage.setItem(key, JSON.stringify(data));
}

function clearData() {
  localStorage.clear();
}

function removeData(key: string) {
  localStorage.removeItem(key);
}

export { loadData, saveData, clearData, removeData };
