import { get, post, put } from '../../services/api/api';
import {
  addHiringManager,
  hiringManagerList,
  user,
  validateAdmin,
  updateHiringManager,
  addAdmin
} from '../../services/api/apiConstants';

export const getHiringManagerListService = () => get(hiringManagerList);
export const getUserService = () => get(user);
export const validateAdminService = (data: any) => post(validateAdmin, data);
export const hiringManagerService = (data: any) => post(addHiringManager, data);
export const addAdminService = (data: any, urlParams: string) => post(addAdmin + urlParams, data);

export const updateHiringManagerService = (data: any, urlParams: string) =>
  put(updateHiringManager + urlParams, data);
