export const GET_HIRING_MANAGER_LIST = 'GET_HIRING_MANAGER_LIST';
export const GET_HIRING_MANAGER_LIST_REQUEST = 'GET_HIRING_MANAGER_LIST_REQUEST';
export const GET_HIRING_MANAGER_LIST_SUCCESS = 'GET_HIRING_MANAGER_LIST_SUCCESS';
export const GET_HIRING_MANAGER_LIST_FAILED = 'GET_HIRING_MANAGER_LIST_FAILED';

export const GET_USER = 'GET_USER';
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILED = 'GET_USER_FAILED';

export const VALIDATE_ADMIN = 'VALIDATE_ADMIN';
export const VALIDATE_ADMIN_REQUEST = 'VALIDATE_ADMIN_REQUEST';
export const VALIDATE_ADMIN_SUCCESS = 'VALIDATE_ADMIN_SUCCESS';
export const VALIDATE_ADMIN_FAILED = 'VALIDATE_ADMIN_FAILED';

export const ADD_HIRING_MANAGER = 'ADD_HIRING_MANAGER';
export const ADD_HIRING_MANAGER_REQUEST = 'ADD_HIRING_MANAGER_REQUEST';
export const ADD_HIRING_MANAGER_SUCCESS = 'ADD_HIRING_MANAGER_SUCCESS';
export const ADD_HIRING_MANAGER_FAILED = 'ADD_HIRING_MANAGER_FAILED';

export const UPDATE_HIRING_MANAGER = 'UPDATE_HIRING_MANAGER';
export const UPDATE_HIRING_MANAGER_REQUEST = 'UPDATE_HIRING_MANAGER_REQUEST';
export const UPDATE_HIRING_MANAGER_SUCCESS = 'UPDATE_HIRING_MANAGER_SUCCESS';
export const UPDATE_HIRING_MANAGER_FAILED = 'UPDATE_HIRING_MANAGER_FAILED';

export const ADD_ADMIN = 'ADD_ADMIN';
export const ADD_ADMIN_REQUEST = 'ADD_ADMIN_REQUEST';
export const ADD_ADMIN_SUCCESS = 'ADD_ADMIN_SUCCESS';
export const ADD_ADMIN_FAILED = 'ADD_ADMIN_FAILED';
