import React, { useEffect, useState } from 'react';
import { FontWeight, Heading1 } from '../../../../components/atoms/typography/typography';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ButtonType } from '../../../../models/ui.enum';
import { PrimaryButton } from '../../../../components/molecules/buttons/buttons';
import { useDispatch } from 'react-redux';
import {
  addAdminAction,
  getHiringManagerListAction
} from '../../../../redux/states/hiring-mangers/hiringManagers.actions';
import { getLoaderValue } from '../../../../redux/states/ui/ui.services';
import { useSelector } from 'react-redux';
import { LOADING_OFF, LOADING_ON } from '../../../../redux/states/ui/ui.actions';
import { showAlert } from '../../../../utils/utils';
import { HiringManagerDto } from '../../../../redux/states/jobs/jobs.dto';
import SelectInput from '../../../../components/organism/select-input/selectInput';

function AddNewAdmin() {
  const [email, setEmail] = useState<string>('');
  const [adminEmails, setAdminEmails] = useState<string[]>([]);

  const { jobId } = useParams();
  const { state } = useLocation();
  const { role } = state;

  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const isLoading = useSelector(getLoaderValue);

  const { data: hiringMangers } = useSelector(
    (value: any) => value?.hiringManager?.hiringManagerList
  );

  useEffect(() => {
    dispatch(getHiringManagerListAction());
  }, []);

  useEffect(() => {
    const adminEmailList = hiringMangers
      ?.filter((hiringManger: HiringManagerDto) => {
        return !hiringManger?.roles?.includes('ADMIN');
      })
      .map((hiringManger: HiringManagerDto) => hiringManger.email ?? 'NA');

    setAdminEmails(adminEmailList);
  }, [hiringMangers]);

  async function addAdmin() {
    const data = {
      email
    };
    try {
      const res = await dispatch(addAdminAction(jobId ?? '', data));
      dispatch({ type: LOADING_OFF });
      if (res.status === 200) {
        navigate(-1);
      } else {
        showAlert(dispatch, res.message);
      }
    } catch (e) {
      dispatch({ type: LOADING_OFF });
      console.log(e);
    }
  }

  function handleSubmit() {
    dispatch({ type: LOADING_ON });
    addAdmin();
  }

  return (
    <div className="p-5">
      <div className="mb-5">
        <div className="mb-5">
          <Heading1 fontWeight={FontWeight.REGULAR}>
            <>JD : {role}</>
          </Heading1>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-5">
          <form
            onSubmit={(e: any) => {
              e?.preventDefault();
              handleSubmit();
            }}
          >
            <SelectInput
              labelText="Select Admin"
              setData={(e) => {
                setEmail(e);
              }}
              optionsList={['Click to select', ...adminEmails]}
            />
            <PrimaryButton
              className="w-100 mt-4"
              handleBtnClick={() => {}}
              type={ButtonType.SUBMIT}
              isLoading={isLoading}
              loadingText="Please wait"
            >
              Add
            </PrimaryButton>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddNewAdmin;
