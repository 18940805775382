import { X } from 'phosphor-react';
import React, { useState, useEffect } from 'react';

interface TagInputProps {
  onTagsChange: (tags: any[]) => void;
  errorMsg?: string;
  hint?: string;
  maxAttributes: number;
  handleError: (errorMsg: string) => void;
  handleHint: (hint: string) => void;
  alreadyTags?: any[];
}

const TagInput: React.FC<TagInputProps> = ({
  onTagsChange,
  errorMsg,
  maxAttributes,
  handleError,
  handleHint,
  hint,
  alreadyTags
}) => {
  const [inputValue, setInputValue] = useState<any>('');
  const [tags, setTags] = useState<any[]>([]);

  useEffect(() => {
    if (alreadyTags?.length != 0) {
      setTags(alreadyTags ?? []);
    }
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);

    if (inputValue.length >= 2) {
      handleHint('Press enter to add attribute');
    } else {
      handleHint('');
    }
  };
  function updateTags(value: string) {
    const currentTag = { attribute: value.trim() };
    if (tags.length > maxAttributes - 1) {
      handleError(`Max ${maxAttributes} are allowed `);
      handleHint('');
    } else {
      setTags([...tags, currentTag]);
      setInputValue('');
    }
  }

  const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      updateTags(inputValue);
      handleHint('');
    }
  };

  const handleInputBlur = () => {
    if (inputValue.trim() !== '') {
      updateTags(inputValue);
    }
    onTagsChange(tags);
  };

  const handleTagDelete = (index: number) => {
    const updatedTags = [...tags];

    updatedTags.splice(index, 1);
    setTags(updatedTags);
    if (tags.length <= maxAttributes) {
      handleError('');
    }
  };

  useEffect(() => {
    onTagsChange(tags);
  }, [tags, onTagsChange]);

  return (
    <div>
      <div className="text-left mb-1">
        <label htmlFor={'attributes'} className="normal-text font-color-gray">
          Attributes
        </label>
      </div>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyPress={handleInputKeyPress}
        onBlur={handleInputBlur}
        placeholder="Type and press Enter to add attributes"
        className="form-control primary-input"
        id="attributes"
      />
      <div className="text-danger mt-1 text-left small">{errorMsg}</div>
      <div className="text-secondary mt-1 text-left small">{hint}</div>
      <div className="mt-2 mb-4 d-flex flex-wrap">
        {tags.map((tag, index) => (
          <div
            key={index}
            className="badge bg-secondary me-2 mb-2 d-flex align-items-center"
            role="button"
          >
            {tag?.attribute}
            <X
              size={12}
              weight="bold"
              color="var(--white)"
              onClick={() => handleTagDelete(index)}
              className="ms-1"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TagInput;
