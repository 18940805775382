import { LoaderType } from '../../../models/ui.enum';
import './styles.css';

interface SkeletonLoaderProps {
  cardType: LoaderType;
  numTiles?: number;
  numCards?: number;
}

const LoadingCard = ({ numCards = 6 }: { numCards?: number }) => {
  return (
    <>
      {[...Array(numCards)].map((_, index) => (
        <div className="col-md-4">
          <div key={index} className="loading-card shimmer-element my-2 mx-1"></div>
        </div>
      ))}
    </>
  );
};

const LoadingTile = ({ numTiles = 6 }: { numTiles?: number }) => {
  return (
    <>
      {[...Array(numTiles)].map((_, index) => (
        <div key={index} className="loading-tile shimmer-element mb-4"></div>
      ))}
    </>
  );
};

export const SkeletonLoader = ({ cardType, numTiles, numCards }: SkeletonLoaderProps) => {
  switch (cardType) {
    case LoaderType.TILE:
      return (
        <div className="row p-4">
          <LoadingTile numTiles={numTiles} />
        </div>
      );
    case LoaderType.CARD:
      return (
        <div className="row p-4">
          <LoadingCard numCards={numCards} />
        </div>
      );
  }
};
