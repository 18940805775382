/* eslint-disable @typescript-eslint/default-param-last */

import { AlertActionTypes, AlertContentTypes, LoaderActionTypes } from './types';
import {
  ALERT_MODAL_OFF,
  ALERT_MODAL_ON,
  ALERT_MODAL_HEADING,
  LOADING_OFF,
  LOADING_ON,
  ALERT_MODAL_PARA
} from './ui.actions';

const initialState = {
  value: false,
  alert: false,
  alertHeading: '',
  alertPara: ''
};

export default (
  state = initialState,
  action: LoaderActionTypes | AlertActionTypes | AlertContentTypes
) => {
  switch (action.type) {
    case LOADING_ON:
      return { ...state, value: true };
    case LOADING_OFF:
      return { ...state, value: false };
    case ALERT_MODAL_ON:
      return { ...state, alert: true };
    case ALERT_MODAL_OFF:
      return { ...state, alert: false };
    case ALERT_MODAL_HEADING:
      return {
        ...state,
        alertHeading: action.payload
      };
    case ALERT_MODAL_PARA:
      return {
        ...state,
        alertPara: action.payload
      };
    default:
      return state;
  }
};
