import { get, post } from '../../services/api/api';
import {
  addCandidate,
  addCandidateResume,
  addJob,
  exportCandidateList,
  getVideo,
  jdUpload,
  jobAdminList,
  jobDetail,
  jobList,
  jobStats,
  removeJobAdmin,
  updateCandidateInterview
} from '../../services/api/apiConstants';

export const getJobListService = () => get(jobList);

export const getJobStatsService = (urlParams: string) => get(jobStats + urlParams);

export const addJobService = (data: any) => post(addJob, data);

export const addJDService = (data: any) => post(jdUpload, data, 'file');

export const addCandidateResumeService = (data: any, urlParams: string) =>
  post(addCandidateResume + urlParams, data, 'file');

export const addCandidateService = (data: any, urlParams: string) =>
  post(addCandidate + urlParams, data);

export const getJobDetailService = (urlParams: string) => get(jobDetail + urlParams);

export const getJobAdminListService = (urlParams: string) => get(jobAdminList + urlParams);

export const exportCandidateListService = (urlParams: string) =>
  get(exportCandidateList + urlParams, '', 'blob');

export const updateCandidateInterviewService = (data: any, urlParams: string) =>
  post(updateCandidateInterview + urlParams, data);

export const removeJobAdminService = (data: any, urlParams: string) =>
  post(removeJobAdmin + urlParams, data);

export const getVideoService = (urlParams: string) => get(getVideo + urlParams);
