//get job list
export const GET_JOB_LIST = 'GET_JOB_LIST';
export const GET_JOB_LIST_REQUEST = 'GET_JOB_LIST_REQUEST';
export const GET_JOB_LIST_SUCCESS = 'GET_JOB_LIST_SUCCESS';
export const GET_JOB_LIST_FAILED = 'GET_JOB_LIST_FAILED';

//get job stats detail
export const GET_JOB_STATS = 'GET_JOB_STATS';
export const GET_JOB_STATS_REQUEST = 'GET_JOB_STATS_REQUEST';
export const GET_JOB_STATS_SUCCESS = 'GET_JOB_STATS_SUCCESS';
export const GET_JOB_STATS_FAILED = 'GET_JOB_STATS_FAILED';

//add job
export const ADD_JOB = 'ADD_JOB';
export const ADD_JOB_REQUEST = 'GET_JOB_REQUEST';
export const ADD_JOB_SUCCESS = 'ADD_JOB_SUCCESS';
export const ADD_JOB_FAILED = 'ADD_JOB_FAILED';

//add jd
export const ADD_JD = 'ADD_JD';
export const ADD_JD_REQUEST = 'GET_JD_REQUEST';
export const ADD_JD_SUCCESS = 'ADD_JD_SUCCESS';
export const ADD_JD_FAILED = 'ADD_JD_FAILED';

//add candidate
export const ADD_CANDIDATE = 'ADD_CANDIDATE';
export const ADD_CANDIDATE_REQUEST = 'GET_CANDIDATE_REQUEST';
export const ADD_CANDIDATE_SUCCESS = 'ADD_CANDIDATE_SUCCESS';
export const ADD_CANDIDATE_FAILED = 'ADD_CANDIDATE_FAILED';

//get job detail
export const GET_JOB_DETAIL = 'GET_JOB_DETAIL';
export const GET_JOB_DETAIL_REQUEST = 'GET_JOB_DETAIL_REQUEST';
export const GET_JOB_DETAIL_SUCCESS = 'GET_JOB_DETAIL_SUCCESS';
export const GET_JOB_DETAIL_FAILED = 'GET_JOB_DETAIL_FAILED';

//get job Admin List
export const GET_JOB_ADMIN_LIST = 'GET_JOB_ADMIN_LIST';
export const GET_JOB_ADMIN_LIST_REQUEST = 'GET_JOB_ADMIN_LIST_REQUEST';
export const GET_JOB_ADMIN_LIST_SUCCESS = 'GET_JOB_ADMIN_LIST_SUCCESS';
export const GET_JOB_ADMIN_LIST_FAILED = 'GET_JOB_ADMIN_LIST_FAILED';

export const EXPORT_CANDIDATE_LIST = 'EXPORT_CANDIDATE_LIST';
export const EXPORT_CANDIDATE_LIST_REQUEST = 'EXPORT_CANDIDATE_LIST_REQUEST';
export const EXPORT_CANDIDATE_LIST_SUCCESS = 'EXPORT_CANDIDATE_LIST_SUCCESS';
export const EXPORT_CANDIDATE_LIST_FAILED = 'EXPORT_CANDIDATE_LIST_FAILED';

export const UPDATE_CANDIDATE_INTERVIEW = 'UPDATE_CANDIDATE_INTERVIEW';
export const UPDATE_CANDIDATE_INTERVIEW_REQUEST = 'UPDATE_CANDIDATE_INTERVIEW_REQUEST';
export const UPDATE_CANDIDATE_INTERVIEW_SUCCESS = 'UPDATE_CANDIDATE_INTERVIEW_SUCCESS';
export const UPDATE_CANDIDATE_INTERVIEW_FAILED = 'UPDATE_CANDIDATE_INTERVIEW_FAILED';

export const REMOVE_JOB_ADMIN = 'REMOVE_JOB_ADMIN';
export const REMOVE_JOB_ADMIN_REQUEST = 'REMOVE_JOB_ADMIN_REQUEST';
export const REMOVE_JOB_ADMIN_SUCCESS = 'REMOVE_JOB_ADMIN_SUCCESS';
export const REMOVE_JOB_ADMIN_FAILED = 'REMOVE_JOB_ADMIN_FAILED';

export const GET_VIDEO = 'GET_VIDEO';
export const GET_VIDEO_REQUEST = 'GET_VIDEO_REQUEST';
export const GET_VIDEO_SUCCESS = 'GET_VIDEO_SUCCESS';
export const GET_VIDEO_FAILED = 'GET_VIDEO_FAILED';
