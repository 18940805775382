export const handleRequest = (type: string, loading: boolean = true) => {
  return {
    type: type,
    payload: { loading }
  };
};

export const handleSuccess = (type: string, data: any) => {
  return {
    type: type,
    payload: { data }
  };
};

export const handleFailure = (type: string, error: any = 'something went wrong') => {
  return {
    type: type,
    payload: { error }
  };
};
