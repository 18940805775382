import React, { useEffect } from 'react';
import './styles.css';
import HiringManagerList from '../../../components/organism/hiring-manager-list/hiringManagerList';
import { useDispatch } from 'react-redux';
import { getHiringManagerListAction } from '../../../redux/states/hiring-mangers/hiringManagers.actions';
import { useSelector } from 'react-redux';
import { PrimaryButton } from '../../../components/molecules/buttons/buttons';
import { useNavigate } from 'react-router-dom';
import { Plus } from 'phosphor-react';

export default function HiringManagers() {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const { data: hiringManger, loading: loading } = useSelector(
    (state: any) => state?.hiringManager?.hiringManagerList
  );

  useEffect(() => {
    dispatch(getHiringManagerListAction());
  }, []);

  return (
    <div className="p-5">
      <div className="d-flex align-items-center justify-content-between flex-wrap mb-4">
        <div></div>
        <PrimaryButton
          handleBtnClick={() => {
            navigate('/add-hiring-managers', {
              state: { hmname: '', hmemail: '' }
            });
          }}
        >
          <>
            <Plus size={20} weight="bold" className="me-1" /> Add Hiring Manager
          </>
        </PrimaryButton>
      </div>
      <HiringManagerList hiringManagers={hiringManger} isLoading={loading} />
    </div>
  );
}
