import { removeData } from '../redux/services/handleData/localStorage';
import {
  ALERT_MODAL_HEADING,
  ALERT_MODAL_ON,
  ALERT_MODAL_PARA
} from '../redux/states/ui/ui.actions';

export function onLogout() {
  removeData('token');
  window.location.reload();
}

export function getNameInitials(fullName: string): string {
  if (!fullName) {
    return '';
  }
  const words = fullName?.trim().split(' ');
  const initials = words
    ?.slice(0, 2)
    ?.map((word) => word.charAt(0).toUpperCase())
    .join('');
  return initials;
}

export function showAlert(dispatch: any, title: string, description?: string) {
  dispatch({ type: ALERT_MODAL_ON });
  dispatch({ type: ALERT_MODAL_HEADING, payload: title });
  dispatch({ type: ALERT_MODAL_PARA, payload: description });
}

export function formatDate(inputDateStr: string) {
  const inputDate = new Date(inputDateStr);

  // Define options for formatting the date
  const options: any = { year: 'numeric', month: 'short', day: 'numeric' };

  // Convert the date to the desired format
  const formattedDate = inputDate.toLocaleDateString('en-US', options);

  return formattedDate;
}
export function convertBinaryToXlsx(binaryData: any, fileName?: string) {
  if (!binaryData) return;
  const url = window.URL.createObjectURL(new Blob([binaryData]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName} ${new Date(Date.now()).toISOString()}.xlsx`);
  document.body.appendChild(link);
  link.click();
}
