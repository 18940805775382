import React from 'react';
import './typography.css';

interface TypographyProps {
  className?: string;
  children: JSX.Element | string | number;
  fontWeight?: FontWeight;
  textColor?: string;
}

export enum FontWeight {
  SEMIBOLD = 'SEMIBOLD',
  MEDIUM = 'MEDIUM',
  REGULAR = 'REGULAR',
  LIGHT = 'LIGHT',
  EXTRA_LIGHT = 'EXTRA_LIGHT'
}

//font-size: 32px; line-height: 48px;
export function Heading1(props: TypographyProps) {
  let fontWeight: string;
  switch (props.fontWeight) {
    case FontWeight.REGULAR:
      fontWeight = 'fw-400';
      break;
    case FontWeight.SEMIBOLD:
      fontWeight = 'fw-600';
      break;
    case FontWeight.MEDIUM:
      fontWeight = 'fw-500';
      break;
    default:
      fontWeight = 'fw-600';
  }
  return (
    <h1 className={`heading-1 ${fontWeight} ${props.className}`} style={{ color: props.textColor }}>
      {props.children}
    </h1>
  );
}

//font-size: 22px; line-height: 28px;
export function Heading2(props: TypographyProps) {
  let fontWeight: string;
  switch (props.fontWeight) {
    case FontWeight.REGULAR:
      fontWeight = 'fw-400';
      break;
    case FontWeight.SEMIBOLD:
      fontWeight = 'fw-600';
      break;
    case FontWeight.MEDIUM:
      fontWeight = 'fw-500';
      break;
    default:
      fontWeight = 'fw-500';
  }

  return (
    <h2 className={`heading-2 ${fontWeight} ${props.className}`} style={{ color: props.textColor }}>
      {props.children}
    </h2>
  );
}

//font-size: 16px; line-height: 24px;
export function Para(props: TypographyProps) {
  let fontWeight: string;
  switch (props.fontWeight) {
    case FontWeight.EXTRA_LIGHT:
      fontWeight = 'fw-200';
      break;
    case FontWeight.LIGHT:
      fontWeight = 'fw-300';
      break;
    case FontWeight.SEMIBOLD:
      fontWeight = 'fw-600';
      break;
    case FontWeight.MEDIUM:
      fontWeight = 'fw-500';
      break;
    case FontWeight.REGULAR:
      fontWeight = 'fw-400';
      break;
    default:
      fontWeight = 'fw-300';
  }

  return (
    <h3 className={`para ${fontWeight} ${props.className}`} style={{ color: props.textColor }}>
      {props.children}
    </h3>
  );
}

//font-size: 12px; line-height: 20px;
export function Heading3(props: TypographyProps) {
  let fontWeight: string;
  switch (props.fontWeight) {
    case FontWeight.SEMIBOLD:
      fontWeight = 'fw-600';
      break;
    case FontWeight.MEDIUM:
      fontWeight = 'fw-500';
      break;
    case FontWeight.REGULAR:
      fontWeight = 'fw-400';
      break;
    default:
      fontWeight = 'fw-400';
  }

  return (
    <h4 className={`heading-3 ${fontWeight} ${props.className}`} style={{ color: props.textColor }}>
      {props.children}
    </h4>
  );
}

//font-size: 12px; line-height: 20px;
export function Caption(props: TypographyProps) {
  let fontWeight: string;
  switch (props.fontWeight) {
    case FontWeight.SEMIBOLD:
      fontWeight = 'fw-600';
      break;
    case FontWeight.MEDIUM:
      fontWeight = 'fw-500';
      break;
    case FontWeight.REGULAR:
      fontWeight = 'fw-400';
      break;
    default:
      fontWeight = 'fw-400';
  }

  return (
    <h4 className={`caption ${fontWeight} ${props.className}`} style={{ color: props.textColor }}>
      {props.children}
    </h4>
  );
}
