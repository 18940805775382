import React from 'react';
import './styles.css';
import { FontWeight, Heading2, Para } from '../../atoms/typography/typography';
import { LinkButton } from '../../molecules/buttons/buttons';
import { HiringManagerDto } from '../../../redux/states/jobs/jobs.dto';
import { useNavigate } from 'react-router-dom';
import { SkeletonLoader } from '../skeleton-loader/skeletonLoader';
import { LoaderType } from '../../../models/ui.enum';
import { NoDataComponent } from '../no-data-component/noDataComponent';
import NoData from '../../../assets/no-data.svg';

interface HiringManagerListProps {
  hiringManagers: HiringManagerDto[];
  isLoading?: boolean;
}

export default function HiringManagerList({ hiringManagers, isLoading }: HiringManagerListProps) {
  const navigate = useNavigate();

  function onAddHiringManagerClick() {
    navigate('/add-hiring-managers', {
      state: { hmname: '', hmemail: '' }
    });
  }

  return (
    <>
      {hiringManagers?.length !== 0 ? (
        <div className="table-wrapper">
          <div>
            <div className="p-4">
              <Heading2>Hiring Manager List</Heading2>
            </div>
            {isLoading ? (
              <SkeletonLoader cardType={LoaderType.TILE} />
            ) : (
              <div className="table-responsive">
                <table className="table table-borderless text-center">
                  <thead className={'table-header-area'}>
                    <tr>
                      <th scope="col" className="text-start ps-4">
                        <Para textColor={'var(--textGray)'}>Name</Para>
                      </th>
                      <th scope="col" className="text-start">
                        <Para textColor={'var(--textGray)'}>Email</Para>
                      </th>
                      <th scope="col">
                        <Para textColor={'var(--textGray)'}>Status</Para>
                      </th>
                      <th scope="col">
                        <Para textColor={'var(--textGray)'}>Role</Para>
                      </th>
                      <th scope="col">
                        <Para textColor={'var(--textGray)'}>Action</Para>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {hiringManagers?.map((hiringManager, index) => {
                      return (
                        <tr className={'table-row'} key={index}>
                          <td>
                            <div className="d-flex">
                              <div className="ms-3 text-nowrap">
                                <Para fontWeight={FontWeight.SEMIBOLD}>
                                  {hiringManager?.name ? hiringManager?.name : 'NA '}
                                </Para>
                              </div>
                            </div>
                          </td>

                          <td>
                            <div className="d-flex">
                              <Para fontWeight={FontWeight.MEDIUM} textColor={'var(--textGray)'}>
                                {hiringManager?.email ? hiringManager?.email : 'NA'}
                              </Para>
                            </div>
                          </td>
                          <td>
                            <Para>{hiringManager?.inActive ? '--' : 'Active'}</Para>
                          </td>

                          <td>
                            {hiringManager?.roles.map((role, roleIndex) => (
                              <span className="badge bg-success me-2" key={roleIndex}>
                                {role === 'ADMIN' ? 'Admin' : 'Hiring Manager'}
                              </span>
                            ))}
                          </td>
                          <td>
                            <LinkButton
                              handleBtnClick={() => {
                                navigate('/add-hiring-managers', {
                                  state: {
                                    hmname: hiringManager?.name,
                                    hmInActive: hiringManager?.inActive,
                                    id: hiringManager?._id
                                  }
                                });
                              }}
                              className="mx-auto"
                              isDisabled={hiringManager?.roles[0] === 'ADMIN' ? true : false}
                            >
                              Edit
                            </LinkButton>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      ) : (
        <NoDataComponent
          image={NoData}
          text="Add your first Hiring Manager here"
          ctaBtnText="Add New Hiring Manager"
          onBtnClick={onAddHiringManagerClick}
        ></NoDataComponent>
      )}
    </>
  );
}
