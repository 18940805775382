import { useEffect, useRef, useState } from 'react';
import { CandidateDto, JobDetailDto, ScoreDto } from '../../../redux/states/jobs/jobs.dto';
import { FontWeight, Heading3, Para } from '../../atoms/typography/typography';
import { ArrowDown, ArrowUp, WarningCircle } from 'phosphor-react';
import { NoDataComponent } from '../no-data-component/noDataComponent';
import CandidateDetailsSidebar from '../candidate-details-sidebar/candidateDetailsSidebar';
import { InterviewStatusType } from '../../../models/ui.enum';

interface CandidateListProps {
  jobDetails: JobDetailDto;
  completedInterviews: any;
  matchingCandidates: CandidateDto[];
  checkedCandidates: (selectedCandidateIds: string[]) => void;
}

function CandidateListNew({
  jobDetails,
  completedInterviews,
  matchingCandidates,
  checkedCandidates
}: CandidateListProps) {
  var arrowDirection = [false];
  jobDetails?.jd?.attributes.map(() => arrowDirection.push(false));
  const [arrow, setArrow] = useState<boolean[]>(arrowDirection);

  const [candidateDetails, setCandidateDetails] = useState<CandidateDto | null>(null);

  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState<number[]>([]);
  const [selectedCandidateIds, setSelectedCandidateIds] = useState<string[]>([]);
  const [candidatesSorted, setCandidatesSorted] = useState<boolean>(true);
  const [sortedList, setSortedList] = useState<CandidateDto[]>(matchingCandidates);

  useEffect(() => {
    completedInterviews(InterviewStatusType.INVITED);
  }, []);

  function handleCandidateClick(candidate: CandidateDto) {
    setCandidateDetails(candidate);
  }

  function descendingOrder(index: number) {
    const isOverallRating = index === matchingCandidates[0]?.score?.length;

    const scoreDescending = [...matchingCandidates].sort((a, b) => {
      const scoreA = isOverallRating ? a?.overallRating : a?.score?.[index]?.score;
      const scoreB = isOverallRating ? b?.overallRating : b?.score?.[index]?.score;

      return scoreB - scoreA;
    });

    return scoreDescending;
  }

  var indexCheck = useRef(-1);
  function sortCandidates(index: number) {
    if (index === indexCheck.current) {
      setArrow((prevState: boolean[]) =>
        prevState.map((item: boolean, idx: number) => (idx === index ? !item : false))
      );
      if (candidatesSorted === false) {
        let sorted: CandidateDto[] = descendingOrder(index);

        setSortedList(sorted);
        setCandidatesSorted(true);
      } else {
        setSortedList(matchingCandidates);
        setCandidatesSorted(false);
      }
    } else {
      setArrow((prevState: boolean[]) =>
        prevState.map((item: boolean, idx: number) => (idx === index ? true : false))
      );
      indexCheck.current = index;
      let sorted: CandidateDto[] = descendingOrder(index);
      setSortedList(sorted);
      setCandidatesSorted(true);
    }
  }

  const handleSelectAllChange = () => {
    setSelectAllChecked((prev) => !prev);

    if (selectAllChecked) {
      setCheckedCheckboxes([]);
      setSelectedCandidateIds([]);
    } else {
      const allIndices = matchingCandidates.map((_: CandidateDto, index: number) => index);
      const allIds = matchingCandidates.map((candidate: CandidateDto) => candidate._id);
      setCheckedCheckboxes(allIndices);
      setSelectedCandidateIds(allIds);
    }
  };

  const handleCheckboxChange = (index: number, id: string) => {
    setCheckedCheckboxes((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
    setSelectedCandidateIds((prev) =>
      prev.includes(id) ? prev.filter((checkboxId) => checkboxId !== id) : [...prev, id]
    );
  };

  useEffect(() => {
    checkedCandidates(selectedCandidateIds);
  }, [selectedCandidateIds]);

  useEffect(() => {
    setCheckedCheckboxes([]);
    setSelectedCandidateIds([]);
    setSortedList(matchingCandidates);
    setSelectAllChecked(false);
  }, [matchingCandidates]);

  return (
    <div className="mt-5 table-wrapper">
      {matchingCandidates?.length === 0 ? (
        <NoDataComponent
          icon={<WarningCircle size={50} color="gray" />}
          text="No Candidate found"
        />
      ) : (
        <div className="table-responsive">
          <table className="table table-borderless">
            <thead className={'table-header-area'}>
              <tr>
                <th scope="col" className="ps-4 py-3">
                  <div className="d-flex">
                    <input
                      type="checkbox"
                      className="me-3"
                      checked={selectAllChecked}
                      onChange={handleSelectAllChange}
                    />
                    <Para
                      className=" text-break text-nowrap"
                      fontWeight={FontWeight.MEDIUM}
                      textColor="var(--textGray)"
                    >
                      Name
                    </Para>
                  </div>
                </th>

                <th scope="col" className="py-3">
                  <div className="d-flex">
                    {jobDetails?.jd?.attributes.map((data: any, index: number) => (
                      <div
                        key={index}
                        role="button"
                        className="w-100 "
                        onClick={() => {
                          sortCandidates(index);
                        }}
                      >
                        <Para
                          className="me-3 text-break "
                          fontWeight={FontWeight.MEDIUM}
                          textColor="var(--textGray)"
                        >
                          <div className="d-flex align-items-start text-nowrap">
                            {data?.attribute}
                            <div className="ms-2">
                              {arrow[index] ? <ArrowUp size={16} /> : <ArrowDown size={18} />}
                            </div>
                          </div>
                        </Para>
                      </div>
                    ))}
                  </div>
                </th>
                <th scope="col" className="py-3">
                  <div
                    role="button"
                    className="w-100 "
                    onClick={() => {
                      sortCandidates(jobDetails?.jd?.attributes.length);
                    }}
                  >
                    <Para
                      className="me-3 text-break "
                      fontWeight={FontWeight.MEDIUM}
                      textColor="var(--textGray)"
                    >
                      <div className="d-flex align-items-start text-nowrap">
                        {'Overall Rating'}
                        <div className="ms-2">
                          {arrow[jobDetails?.jd?.attributes.length] ? (
                            <ArrowUp size={16} />
                          ) : (
                            <ArrowDown size={18} />
                          )}
                        </div>
                      </div>
                    </Para>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedList?.map((candidate: CandidateDto, index: number) => {
                return (
                  <tr
                    key={index}
                    onClick={() => handleCandidateClick(candidate)}
                    className={`table-row`}
                  >
                    <td className="ps-4">
                      <div className="d-flex text-nowrap">
                        <div className="w-100" style={{ wordBreak: 'break-all' }}>
                          <div className="custom-control custom-checkbox d-flex">
                            <input
                              type="checkbox"
                              className="custom-control-input me-3"
                              id={`checkbox-${index}`}
                              size={12}
                              checked={checkedCheckboxes.includes(index)}
                              onChange={() => handleCheckboxChange(index, candidate?._id)}
                            />
                            <label className="custom-control-label w-100">
                              <div
                                className={`navbar-toggler ${!candidate?.score ? 'disabled' : ''}`}
                                role="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasNavbar"
                                aria-controls="offcanvasNavbar"
                                aria-label="Toggle navigation"
                              >
                                <Para
                                  fontWeight={FontWeight.MEDIUM}
                                  className="text-decoration-underline text-capitalize"
                                >
                                  {candidate.candidateName ?? 'N/A'}
                                </Para>
                                <Heading3 textColor="var(--textGray)">
                                  {candidate.candidateEmail ?? 'N/A'}
                                </Heading3>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td
                      className={`navbar-toggler ${!candidate?.score ? 'disabled' : ''}`}
                      role="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasNavbar"
                      aria-controls="offcanvasNavbar"
                      aria-label="Toggle navigation"
                    >
                      <div className="d-flex ">
                        {candidate?.score?.map((data: ScoreDto) => (
                          <div className="me-3 w-100 align-items-center">
                            <Para fontWeight={FontWeight.MEDIUM} className="marks ps-3">
                              {data.score}
                            </Para>
                          </div>
                        ))}
                      </div>
                    </td>
                    <td
                      className={`navbar-toggler ${!candidate?.score ? 'disabled' : ''}`}
                      role="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasNavbar"
                      aria-controls="offcanvasNavbar"
                      aria-label="Toggle navigation"
                    >
                      <div className="d-flex ">
                        <div className="me-3 w-100 align-items-center navbar-toggler">
                          <Para fontWeight={FontWeight.MEDIUM} className="marks ps-3">
                            {candidate?.overallRating}
                          </Para>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <CandidateDetailsSidebar candidateDetails={candidateDetails} />
    </div>
  );
}

export default CandidateListNew;
