import React, { useEffect, useState } from 'react';
import { getNameInitials } from '../../../utils/utils';
import { Caption, FontWeight, Heading2, Heading3, Para } from '../../atoms/typography/typography';
import { CandidateDto, ScoreDto } from '../../../redux/states/jobs/jobs.dto';
import { breakLines } from '../../../utils/ui';
import { useDispatch } from 'react-redux';
import { getVideoAction } from '../../../redux/states/jobs/jobs.actions';
import { useSelector } from 'react-redux';
import Loader from '../../molecules/loader/loader';
import './style.css';
import { SmileyXEyes } from 'phosphor-react';

interface CandidateDetailsSidebarDto {
  candidateDetails: CandidateDto | null;
}

interface FeedbackDto {
  feedback: string;
}

const CandidateDetailsSidebar = ({ candidateDetails }: CandidateDetailsSidebarDto) => {
  const [feedbackData, setFeedback] = useState<FeedbackDto>();
  const [selectedAttribute, setSelectedAttribute] = useState<number | null>(0);
  const [activeTab, setActiveTab] = useState('feedback');
  const dispatch: any = useDispatch();

  const { data: snaps, loading: loading } = useSelector((state: any) => state?.job?.getVideo);

  function handleAttributeClick(index: number) {
    setSelectedAttribute(index === selectedAttribute ? null : index);
  }

  async function displayVideo() {
    try {
      dispatch(getVideoAction(candidateDetails?._id ?? ''));
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (candidateDetails && candidateDetails.score && candidateDetails.score.length > 0) {
      setFeedback({ feedback: candidateDetails.score[0]?.feedback });
      setSelectedAttribute(0);
      setActiveTab('feedback');
    }
  }, [candidateDetails]);

  return (
    <div>
      <nav className="navbar">
        <div className="container-fluid">
          <div
            className="offcanvas offcanvas-end sidebar-size px-3 py-2 overflow-auto"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                <div className=" d-flex align-items-center">
                  <div className="name-initials-avatar me-2">
                    {getNameInitials(candidateDetails?.candidateName ?? '')}
                  </div>

                  <div className="me-2">
                    <Heading3 fontWeight={FontWeight.MEDIUM} className="text-capitalize">
                      {candidateDetails?.candidateName ?? ''}
                    </Heading3>
                    <Caption textColor="var(--textGray)">
                      {candidateDetails?.candidateEmail ?? ''}
                    </Caption>
                  </div>
                </div>
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <nav>
              <div className="nav nav-tabs mt-4" id="nav-tab" role="tablist">
                <button
                  data-bs-toggle="tab"
                  type="button"
                  role="tab"
                  aria-selected="true"
                  className={`nav-link ${activeTab === 'feedback' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveTab('feedback');
                  }}
                >
                  Feedback
                </button>
                <button
                  data-bs-toggle="tab"
                  type="button"
                  role="tab"
                  aria-selected="false"
                  className={`nav-link ${activeTab === 'snaps' ? 'active' : ''}`}
                  onClick={() => {
                    displayVideo();
                    setActiveTab('snaps');
                  }}
                >
                  Interview Snaps
                </button>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              {activeTab === 'feedback' ? (
                <div>
                  <div className="d-flex " role="button">
                    <div className="d-flex pt-4  justify-content-center">
                      <Para className="text-break attr" fontWeight={FontWeight.MEDIUM}>
                        <div className="d-flex justify-content-center ">
                          <div className="list-unstyled w-100 no-white-spaces nav-pills">
                            {candidateDetails?.score?.map((data: ScoreDto, index: number) => (
                              <div
                                key={index}
                                className={`py-4 pill ${
                                  selectedAttribute === index ? 'active' : ''
                                }`}
                                onClick={() => {
                                  setFeedback({
                                    feedback: data?.feedback
                                  });
                                  handleAttributeClick(index);
                                }}
                              >
                                {[data?.attribute, '  ', `(${data?.score})`]}
                              </div>
                            ))}
                          </div>
                        </div>
                      </Para>
                    </div>
                    <div className="vertical-line"></div>
                    <div className="w-75">
                      {feedbackData && (
                        <div className="text-start pt-4 ps-4">
                          <Para className="pt-4" textColor="var(--textGray">
                            {feedbackData?.feedback ? breakLines(feedbackData.feedback) : ''}
                          </Para>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {snaps?.videoUrl ? (
                    <div className="p-4">
                      <Para>
                        These images, captured during interviews, provide valuable insights into
                        candidate behaviour and interview conduct, enabling a more thorough
                        evaluation for you.
                      </Para>
                      {loading ? (
                        <Loader />
                      ) : (
                        <iframe
                          width="100%"
                          height="345"
                          className="rounded mt-4"
                          src={snaps?.videoUrl}
                        ></iframe>
                      )}
                    </div>
                  ) : (
                    <div className="d-flex flex-column align-items-center justify-content-center text-center p-5">
                      <SmileyXEyes size={64} />
                      <Heading2 className="pt-2">No Result</Heading2>
                      <Para className="pt-2">
                        Images are unavailable because the candidate encountered a camera-opening
                        issue during the interview
                      </Para>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default CandidateDetailsSidebar;
