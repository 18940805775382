import React, { useState } from 'react';
import {
  Caption,
  FontWeight,
  Heading1,
  Para
} from '../../../../components/atoms/typography/typography';
import './styles.css';
import { CheckCircle, CloudArrowUp } from 'phosphor-react';
import { PrimaryButton, SecondaryButton } from '../../../../components/molecules/buttons/buttons';
import FileUploadIcon from '../../../../assets/file-upload-icon.svg';
import { useDispatch } from 'react-redux';
import {
  addCandidateAction,
  addCandidateResumeAction
} from '../../../../redux/states/jobs/jobs.actions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PrimaryTextfield } from '../../../../components/molecules/textfield/textfield';
import { ButtonType, LoaderType } from '../../../../models/ui.enum';
import UploadFile from '../../../../components/organism/upload-file/uploadFile';
import { useSelector } from 'react-redux';
import { getLoaderValue } from '../../../../redux/states/ui/ui.services';
import { LOADING_OFF, LOADING_ON } from '../../../../redux/states/ui/ui.actions';
import { SkeletonLoader } from '../../../../components/organism/skeleton-loader/skeletonLoader';
import { showAlert } from '../../../../utils/utils';

export default function AddCandidate() {
  const dispatch: any = useDispatch();
  const { jobId } = useParams();
  const { state } = useLocation();
  const { role } = state;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [resumeId, setResumeId] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [fileErrorMsg, setFileErrorMsg] = useState('');

  const [selectedFile, setSelectedFile] = useState<any>(null);
  const navigate = useNavigate();
  const isLoading = useSelector(getLoaderValue);

  const handleUploadedFileData = async (formData: any) => {
    setLoading(true);
    try {
      const res = await dispatch(addCandidateResumeAction(jobId, formData));
      if (res.status === 200) {
        console.log(res);
        setEmail(res?.data?.data?.summaryInfo?.email);
        setName(res?.data?.data?.summaryInfo?.name);
        setResumeId(res?.data?.data?._id);
        setLoading(false);
      } else {
        showAlert(dispatch, res.message);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  async function addCandidate() {
    const data = {
      resumeId,
      name,
      email
    };
    try {
      const res = await dispatch(addCandidateAction(jobId, data));
      dispatch({ type: LOADING_OFF });
      if (res.status === 200) {
        navigate(-1);
      } else {
        showAlert(dispatch, res.message);
      }
    } catch (e) {
      dispatch({ type: LOADING_OFF });
      console.log(e);
    }
  }

  function handleSubmit() {
    if (!selectedFile) {
      setFileErrorMsg('Please upload a file');

      return;
    } else {
      dispatch({ type: LOADING_ON });
      addCandidate();
    }
  }

  return (
    <div className="p-5">
      <div className="mb-5">
        <Heading1 fontWeight={FontWeight.REGULAR}>
          <>{role}</>
        </Heading1>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-5">
          <form
            onSubmit={(e: any) => {
              e?.preventDefault();
              handleSubmit();
            }}
          >
            <UploadFile
              label="Click to upload Candidate Resume"
              errorMessage={fileErrorMsg}
              onFileUpload={(data) => {
                handleUploadedFileData(data);
              }}
              onFileSelected={(file: any) => {
                setSelectedFile(file);
              }}
            />
            {loading ? (
              <SkeletonLoader cardType={LoaderType.TILE} numTiles={3} />
            ) : (
              <div>
                <PrimaryTextfield
                  onChange={(value) => setName(value)}
                  label="Name"
                  placeholder="Candidate Name"
                  value={name}
                  fieldName="name"
                  type="text"
                  required
                />
                <PrimaryTextfield
                  onChange={(value) => {
                    setEmail(value);
                  }}
                  label="Email"
                  placeholder="Candidate Email"
                  value={email}
                  fieldName=""
                  type="email"
                  required
                />

                <PrimaryButton
                  className="w-100"
                  handleBtnClick={() => {}}
                  type={ButtonType.SUBMIT}
                  isLoading={isLoading}
                  loadingText="Please wait"
                >
                  Add
                </PrimaryButton>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
