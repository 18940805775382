import { Dispatch } from 'redux';
import { handleRequest, handleSuccess, handleFailure } from '../../services/handleData/handleData';
import {
  GET_HIRING_MANAGER_LIST_REQUEST,
  GET_HIRING_MANAGER_LIST_SUCCESS,
  GET_HIRING_MANAGER_LIST_FAILED,
  GET_USER_SUCCESS,
  GET_USER_FAILED,
  GET_USER_REQUEST,
  VALIDATE_ADMIN_REQUEST,
  VALIDATE_ADMIN_FAILED,
  VALIDATE_ADMIN_SUCCESS,
  ADD_HIRING_MANAGER_REQUEST,
  ADD_HIRING_MANAGER_SUCCESS,
  ADD_HIRING_MANAGER_FAILED,
  UPDATE_HIRING_MANAGER_REQUEST,
  UPDATE_HIRING_MANAGER_SUCCESS,
  UPDATE_HIRING_MANAGER_FAILED,
  ADD_ADMIN_REQUEST,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_FAILED
} from './hiringManagers.constant';
import {
  addAdminService,
  getHiringManagerListService,
  getUserService,
  hiringManagerService,
  updateHiringManagerService,
  validateAdminService
} from './hiringManagers.services';

export const getHiringManagerListAction = () => async (dispatch: Dispatch) => {
  dispatch(handleRequest(GET_HIRING_MANAGER_LIST_REQUEST));
  try {
    const res = await getHiringManagerListService();
    dispatch(handleSuccess(GET_HIRING_MANAGER_LIST_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(GET_HIRING_MANAGER_LIST_FAILED, ex?.message));
    return ex;
  }
};

export const getUserAction = () => async (dispatch: Dispatch) => {
  dispatch(handleRequest(GET_USER_REQUEST));
  try {
    const res = await getUserService();
    dispatch(handleSuccess(GET_USER_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(GET_USER_FAILED, ex?.message));
    return ex;
  }
};

// add jd
export const validateAdminAction = (data: any) => async (dispatch: Dispatch) => {
  dispatch(handleRequest(VALIDATE_ADMIN_REQUEST, true));
  try {
    const res = await validateAdminService(data);
    dispatch(handleSuccess(VALIDATE_ADMIN_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(VALIDATE_ADMIN_FAILED, ex));
    return ex;
  }
};

//add hiring manager
export const addHiringManagerAction = (data: any) => async (dispatch: Dispatch) => {
  dispatch(handleRequest(ADD_HIRING_MANAGER_REQUEST, true));
  try {
    const res = await hiringManagerService(data);
    dispatch(handleSuccess(ADD_HIRING_MANAGER_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(ADD_HIRING_MANAGER_FAILED, ex));
    return ex;
  }
};

export const updateHiringManagerAction = (id: string, data: any) => async (dispatch: Dispatch) => {
  const urlParams = `?id=${id}`;

  dispatch(handleRequest(UPDATE_HIRING_MANAGER_REQUEST, true));
  try {
    const res = await updateHiringManagerService(data, urlParams);
    dispatch(handleSuccess(UPDATE_HIRING_MANAGER_SUCCESS, res?.data));
    return res;
  } catch (ex) {
    dispatch(handleFailure(UPDATE_HIRING_MANAGER_FAILED, ex));
    return ex;
  }
};

export const addAdminAction = (jobId: string, data: any) => async (dispatch: Dispatch) => {
  const urlParams = `?jobId=${jobId}`;
  dispatch(handleRequest(ADD_ADMIN_REQUEST, true));
  try {
    const res = await addAdminService(data, urlParams);
    dispatch(handleSuccess(ADD_ADMIN_SUCCESS, res?.data));
    return res;
  } catch (ex) {
    dispatch(handleFailure(ADD_ADMIN_FAILED, ex));
    return ex;
  }
};
