import { combineReducers } from 'redux';
import * as Types from './jobs.constant';

function makeRequestReducer(actionType: any, defaultData = [], state = {}) {
  const initialState = {
    data: defaultData,
    error: false,
    loading: false,
    errorMsg: '',
    ...state
  };
  // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/default-param-last
  return (state = initialState, action: any) => {
    switch (action?.type) {
      case `${actionType}_REQUEST`:
        return {
          ...state,
          loading: true,
          error: false
        };
      case `${actionType}_SUCCESS`:
        return {
          ...state,
          loading: false,
          error: false,
          data: action?.payload?.data,
          errorMsg: ''
        };
      case `${actionType}_FAILED`:
        return {
          ...state,
          loading: false,
          error: true,
          data: defaultData,
          errorMsg: action?.payload?.error
        };
      case `${actionType}_RESET`:
        return state;
      default:
        return state;
    }
  };
}

export default combineReducers({
  jobList: makeRequestReducer(Types.GET_JOB_LIST, []),
  jobDetails: makeRequestReducer(Types.GET_JOB_DETAIL),
  jobStats: makeRequestReducer(Types.GET_JOB_STATS),
  jobAdminList: makeRequestReducer(Types.GET_JOB_ADMIN_LIST),
  exportCandidateList: makeRequestReducer(Types.EXPORT_CANDIDATE_LIST),
  getVideo: makeRequestReducer(Types.GET_VIDEO)
});
