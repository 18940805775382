import { useState } from 'react';
import { FontWeight, Heading1 } from '../../../../components/atoms/typography/typography';
import './styles.css';
import { PrimaryTextfield } from '../../../../components/molecules/textfield/textfield';
import { PrimaryButton, SecondaryButton } from '../../../../components/molecules/buttons/buttons';
import { useDispatch } from 'react-redux';
import { addJDAction, addJobAction } from '../../../../redux/states/jobs/jobs.actions';
import { ButtonType, LoaderType } from '../../../../models/ui.enum';
import { useNavigate } from 'react-router-dom';
import TagInput from '../../../../components/molecules/tagInput/tagInput';
import UploadFile from '../../../../components/organism/upload-file/uploadFile';

import SelectInput from '../../../../components/organism/select-input/selectInput';
import { useSelector } from 'react-redux';
import { getLoaderValue } from '../../../../redux/states/ui/ui.services';
import { LOADING_OFF, LOADING_ON } from '../../../../redux/states/ui/ui.actions';
import { SkeletonLoader } from '../../../../components/organism/skeleton-loader/skeletonLoader';
import { showAlert } from '../../../../utils/utils';
import { toast } from 'react-toastify';

export default function AddJob() {
  const [role, setRole] = useState('');
  const [duration, setDuration] = useState('');
  const [attributes, setAttributes] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [fileErrorMsg, setFileErrorMsg] = useState('');
  const [attributesErrorMsg, setAttributesErrorMsg] = useState('');
  const [hint, setHint] = useState('');
  const [seniority, setSeniority] = useState('Begineer(0-1Years)');
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(getLoaderValue);

  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [file, setFileData] = useState<any>(null);

  const handleUploadedFileData = async (formData: any) => {
    setLoading(true);
    try {
      const res = await dispatch(addJDAction(formData));
      if (res.status === 200) {
        const jobSummary = res?.data?.data?.summaryInfo;
        setRole(jobSummary?.jobRole);
        const fetchedAttributes = jobSummary?.attributes.map((attribute: any) => ({
          attribute: attribute
        }));
        setAttributes(fetchedAttributes);
        setFileData(res?.data?.data?._id);
        setLoading(false);
      } else {
        showAlert(dispatch, res.message);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  async function uploadJob() {
    const data = {
      role: role,
      duration: duration,
      startDate,
      endDate,
      seniority,
      jd: {
        documentId: file,
        attributes: attributes
      }
    };
    try {
      const res = await dispatch(addJobAction(data));
      dispatch({ type: LOADING_OFF });
      if (res.status === 200) {
        navigate(-1);
        toast.success('Job added successfully');
      } else {
        showAlert(dispatch, res.message);
      }
    } catch (e) {
      dispatch({ type: LOADING_OFF });
      console.log(e);
    }
  }

  const handleFormKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent Enter key from submitting the form
    }
  };

  function handleSubmit() {
    if (!selectedFile) {
      setFileErrorMsg('Please upload a file');

      return;
    } else if (attributes.length == 0) {
      setAttributesErrorMsg('This field is required');
    } else if (attributes.length > 4) {
      setAttributesErrorMsg('Max 4 attributes allowed');
    } else {
      dispatch({ type: LOADING_ON });
      uploadJob();
    }
  }

  return (
    <div className="p-5">
      <form
        onSubmit={(e: any) => {
          e?.preventDefault();
          handleSubmit();
        }}
        onKeyPress={handleFormKeyPress}
      >
        <div className="mb-5">
          <Heading1 fontWeight={FontWeight.REGULAR}>Post a Job</Heading1>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-5">
            <div className="mb-4">
              <UploadFile
                label="Click to Upload JD"
                errorMessage={fileErrorMsg}
                onFileUpload={(data) => {
                  handleUploadedFileData(data);
                }}
                onFileSelected={(pdfFile: any) => {
                  setSelectedFile(pdfFile);
                }}
              />
            </div>

            {loading ? (
              <SkeletonLoader cardType={LoaderType.TILE} />
            ) : (
              <div>
                <PrimaryTextfield
                  onChange={(value) => setRole(value)}
                  label="Role"
                  placeholder="Role"
                  value={role}
                  fieldName="role"
                  type="text"
                  required
                />

                <div className="mb-3 position-relative">
                  <SelectInput
                    labelText="Seniority"
                    setData={(e) => {
                      setSeniority(e);
                    }}
                    optionsList={[
                      'Beginer(0-1Years)',
                      'Early Career(1-3years)',
                      'Mid Career(3-7years)',
                      'Late Career(7+years )'
                    ]}
                  />
                </div>

                <PrimaryTextfield
                  onChange={(value) => {
                    setDuration(value);
                  }}
                  label="Duration in min"
                  placeholder="Interview Duration"
                  value={duration}
                  fieldName="number"
                  type="number"
                  required
                />

                <PrimaryTextfield
                  onChange={(value) => {
                    setStartDate(value);
                  }}
                  label="Start Date"
                  placeholder="Enter Interview Start Date"
                  value={startDate}
                  fieldName="date"
                  type="date"
                  required
                  max={endDate}
                />

                <PrimaryTextfield
                  onChange={(value) => {
                    setEndDate(value);
                  }}
                  label="End Date"
                  placeholder="Enter Interview End Date"
                  value={endDate}
                  fieldName="endDate"
                  type="date"
                  min={startDate}
                  required
                />

                <TagInput
                  onTagsChange={(value: any[]) => {
                    setAttributes(value);
                  }}
                  errorMsg={attributesErrorMsg}
                  maxAttributes={4}
                  handleError={(value: string) => setAttributesErrorMsg(value)}
                  handleHint={(value: string) => setHint(value)}
                  hint={hint}
                  alreadyTags={attributes}
                />
                <div className="row">
                  <div className="col">
                    <SecondaryButton
                      className="w-100"
                      handleBtnClick={() => {
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </SecondaryButton>
                  </div>
                  <div className="col">
                    <PrimaryButton
                      className="w-100"
                      handleBtnClick={() => {}}
                      type={ButtonType.SUBMIT}
                      isLoading={isLoading}
                      loadingText="Please wait"
                    >
                      Save
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
