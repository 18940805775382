import { combineReducers } from 'redux';
import jobsReducer from './states/jobs/jobs.reducer';
import hiringManagersReducer from './states/hiring-mangers/hiringManagers.reducer';
import uiReducer from './states/ui/ui.reducer';

const rootReducer = combineReducers<any>({
  job: jobsReducer,
  hiringManager: hiringManagersReducer,
  ui: uiReducer
});

export default rootReducer;
