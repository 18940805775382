import React from 'react';
import NoData from '../../../../src/assets/no-data.svg';
import { useNavigate } from 'react-router-dom';
import { FontWeight, Heading2, Para } from '../../atoms/typography/typography';
import { PrimaryButton } from '../../molecules/buttons/buttons';
import { Plus } from 'phosphor-react';
import { title } from 'process';

interface NoDataComponentDto {
  stateData?: object;
  text: string;
  navigateLink?: string;
  image?: string;
  ctaBtnText?: string;
  icon?: JSX.Element;
  onBtnClick?: () => void;
  isDisabled?: boolean;
}

export const NoDataComponent = (props: NoDataComponentDto) => {
  let { text, image, ctaBtnText, icon, onBtnClick, isDisabled } = props;

  return (
    <div className="p-5">
      <div className="d-flex flex-column align-items-center justify-content-center">
        {image ? (
          <img
            src={image}
            role={onBtnClick && 'button'}
            onClick={() => {
              if (onBtnClick && !isDisabled) onBtnClick();
            }}
          />
        ) : (
          icon
        )}

        <Para className="my-3" fontWeight={FontWeight.REGULAR} textColor="var(--textGray)">
          {text}
        </Para>

        {ctaBtnText && (
          <PrimaryButton
            handleBtnClick={() => {
              if (onBtnClick) onBtnClick();
            }}
            isDisabled={isDisabled}
          >
            <>
              <Plus size={20} weight="bold" className="me-1" /> {ctaBtnText}
            </>
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};
