import { Dispatch } from 'redux';
import { handleRequest, handleSuccess, handleFailure } from '../../services/handleData/handleData';
import {
  GET_JOB_LIST_REQUEST,
  GET_JOB_LIST_SUCCESS,
  GET_JOB_LIST_FAILED,
  GET_JOB_DETAIL_REQUEST,
  GET_JOB_DETAIL_SUCCESS,
  GET_JOB_DETAIL_FAILED,
  GET_JOB_STATS_REQUEST,
  GET_JOB_STATS_SUCCESS,
  GET_JOB_STATS_FAILED,
  ADD_JOB_REQUEST,
  ADD_JOB_SUCCESS,
  ADD_JOB_FAILED,
  ADD_JD_REQUEST,
  ADD_JD_SUCCESS,
  ADD_JD_FAILED,
  ADD_CANDIDATE_REQUEST,
  ADD_CANDIDATE_SUCCESS,
  ADD_CANDIDATE_FAILED,
  GET_JOB_ADMIN_LIST_REQUEST,
  GET_JOB_ADMIN_LIST_SUCCESS,
  GET_JOB_ADMIN_LIST_FAILED,
  EXPORT_CANDIDATE_LIST_REQUEST,
  EXPORT_CANDIDATE_LIST_SUCCESS,
  EXPORT_CANDIDATE_LIST_FAILED,
  UPDATE_CANDIDATE_INTERVIEW_REQUEST,
  UPDATE_CANDIDATE_INTERVIEW_SUCCESS,
  UPDATE_CANDIDATE_INTERVIEW_FAILED,
  REMOVE_JOB_ADMIN_REQUEST,
  REMOVE_JOB_ADMIN_SUCCESS,
  REMOVE_JOB_ADMIN_FAILED,
  GET_VIDEO_REQUEST,
  GET_VIDEO_SUCCESS,
  GET_VIDEO_FAILED
} from './jobs.constant';
import {
  addCandidateResumeService,
  addCandidateService,
  addJDService,
  addJobService,
  exportCandidateListService,
  getJobAdminListService,
  getJobDetailService,
  getJobListService,
  getJobStatsService,
  getVideoService,
  removeJobAdminService,
  updateCandidateInterviewService
} from './jobs.services';

// get job list
export const getJobListAction = () => async (dispatch: Dispatch) => {
  dispatch(handleRequest(GET_JOB_LIST_REQUEST));
  try {
    const res = await getJobListService();
    dispatch(handleSuccess(GET_JOB_LIST_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(GET_JOB_LIST_FAILED, ex?.message));
    return ex;
  }
};

// get job stats
export const getJobStatsAction = (jobId: string) => async (dispatch: Dispatch) => {
  const urlParams = `?jobId=${jobId}`;
  dispatch(handleRequest(GET_JOB_STATS_REQUEST));
  try {
    const res = await getJobStatsService(urlParams);
    dispatch(handleSuccess(GET_JOB_STATS_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(GET_JOB_STATS_FAILED, ex?.message));
    return ex;
  }
};

// add member
export const addJobAction = (data: any) => async (dispatch: Dispatch) => {
  dispatch(handleRequest(ADD_JOB_REQUEST, true));
  try {
    const res = await addJobService(data);
    dispatch(handleSuccess(ADD_JOB_SUCCESS, res?.data));
    return res;
  } catch (ex) {
    console.log('API FAILED', ex);
    dispatch(handleFailure(ADD_JOB_FAILED, ex));
    return ex;
  }
};

export const addJDAction = (data: any) => async (dispatch: Dispatch) => {
  console.log(data);
  dispatch(handleRequest(ADD_JD_REQUEST, true));
  try {
    const res = await addJDService(data);
    dispatch(handleSuccess(ADD_JD_SUCCESS, res?.data));
    return res;
  } catch (ex) {
    dispatch(handleFailure(ADD_JD_FAILED, ex));
    return ex;
  }
};

export const addCandidateResumeAction = (jobId: any, data: any) => async (dispatch: Dispatch) => {
  console.log(data);
  const urlParams = `?jobId=${jobId}`;
  dispatch(handleRequest(ADD_JD_REQUEST, true));
  try {
    const res = await addCandidateResumeService(data, urlParams);
    dispatch(handleSuccess(ADD_JD_SUCCESS, res?.data));
    return res;
  } catch (ex) {
    dispatch(handleFailure(ADD_JD_FAILED, ex));
    return ex;
  }
};

export const addCandidateAction = (jobId: any, data: any) => async (dispatch: Dispatch) => {
  const urlParams = `?jobId=${jobId}`;
  dispatch(handleRequest(ADD_CANDIDATE_REQUEST, true));
  try {
    const res = await addCandidateService(data, urlParams);
    dispatch(handleSuccess(ADD_CANDIDATE_SUCCESS, res?.data));
    return res;
  } catch (ex) {
    dispatch(handleFailure(ADD_CANDIDATE_FAILED, ex));
    return ex;
  }
};

// get job detail
export const getJobDetailAction = (jobId: string) => async (dispatch: Dispatch) => {
  const urlParams = `?id=${jobId}`;
  dispatch(handleRequest(GET_JOB_DETAIL_REQUEST));
  try {
    const res = await getJobDetailService(urlParams);
    dispatch(handleSuccess(GET_JOB_DETAIL_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(GET_JOB_DETAIL_FAILED, ex?.message));
    return ex;
  }
};

// get Job Admin List
export const getJobAdminListAction = (jobId: string) => async (dispatch: Dispatch) => {
  const urlParams = `?jobId=${jobId}`;
  dispatch(handleRequest(GET_JOB_ADMIN_LIST_REQUEST));
  try {
    const res = await getJobAdminListService(urlParams);
    dispatch(handleSuccess(GET_JOB_ADMIN_LIST_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(GET_JOB_ADMIN_LIST_FAILED, ex?.message));
    return ex;
  }
};

export const exportCandidateListAction = (jobId: string) => async (dispatch: Dispatch) => {
  const urlParams = `?jobId=${jobId}`;
  dispatch(handleRequest(EXPORT_CANDIDATE_LIST_REQUEST));
  try {
    const res = await exportCandidateListService(urlParams);
    dispatch(handleSuccess(EXPORT_CANDIDATE_LIST_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(EXPORT_CANDIDATE_LIST_FAILED, ex?.message));
    return ex;
  }
};

export const updateCandidateInterviewAction =
  (jobId: string, data: any) => async (dispatch: Dispatch) => {
    const urlParams = `?jobId=${jobId}`;
    dispatch(handleRequest(UPDATE_CANDIDATE_INTERVIEW_REQUEST));
    try {
      const res = await updateCandidateInterviewService(data, urlParams);
      dispatch(handleSuccess(UPDATE_CANDIDATE_INTERVIEW_SUCCESS, res?.data));
      return res;
    } catch (ex: any) {
      dispatch(handleFailure(UPDATE_CANDIDATE_INTERVIEW_FAILED, ex?.message));
      return ex;
    }
  };

export const removeJobAdminAction = (jobId: string, data: any) => async (dispatch: any) => {
  const urlParams = `?jobId=${jobId}`;
  dispatch(handleRequest(REMOVE_JOB_ADMIN_REQUEST));
  try {
    const res = await removeJobAdminService(data, urlParams);
    dispatch(handleSuccess(REMOVE_JOB_ADMIN_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(REMOVE_JOB_ADMIN_FAILED, ex?.message));
    return ex;
  }
};

export const getVideoAction = (interviewId: string) => async (dispatch: Dispatch) => {
  const urlParams = `?interviewId=${interviewId}`;
  dispatch(handleRequest(GET_VIDEO_REQUEST));
  try {
    const res = await getVideoService(urlParams);
    dispatch(handleSuccess(GET_VIDEO_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(GET_VIDEO_FAILED, ex?.message));
    return ex;
  }
};
