import React, { useEffect } from 'react';
import { PrimaryButton, SecondaryButton } from '../../../components/molecules/buttons/buttons';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Plus } from 'phosphor-react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Loader from '../../../components/molecules/loader/loader';
import NoData from '../../../../src/assets/no-data.svg';
import { FontWeight, Para } from '../../../components/atoms/typography/typography';
import { getJobAdminListAction } from '../../../redux/states/jobs/jobs.actions';
import JobAdminList from '../../../components/organism/admin-list/JobAdminList';
import { SkeletonLoader } from '../../../components/organism/skeleton-loader/skeletonLoader';
import { LoaderType } from '../../../models/ui.enum';

export const JobAdmins = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const { jobId } = useParams();
  const { state: data } = useLocation();
  const { role } = data;

  const { data: adminList, loading: loading } = useSelector(
    (state: any) => state?.job?.jobAdminList
  );

  useEffect(() => {
    dispatch(getJobAdminListAction(jobId ?? ' '));
  }, []);

  return (
    <>
      <div className="p-5">
        <div className="d-flex align-items-center justify-content-end flex-wrap mb-4">
          <PrimaryButton
            className="justify-content-center no-white-space"
            handleBtnClick={() => {
              navigate(`/add-new-admin/${jobId}`, { state: { role: role } });
            }}
          >
            <>
              <Plus size={20} weight="bold" className="me-1" /> Add Admin
            </>
          </PrimaryButton>
        </div>

        {loading ? (
          <SkeletonLoader cardType={LoaderType.TILE} />
        ) : adminList?.length ? (
          <JobAdminList admins={adminList} />
        ) : (
          <div className="p-5">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <img
                src={NoData}
                role="button"
                onClick={() => {
                  navigate(`/add-new-admin/${jobId}`, { state: { role: role } });
                }}
              />
              <Para
                className="align-items-center text-center justify-content-center"
                fontWeight={FontWeight.SEMIBOLD}
              >
                Add first Admin for the job here
              </Para>

              <PrimaryButton
                className="mt-2"
                handleBtnClick={() => {
                  navigate(`/add-new-admin/${jobId}`, { state: { role: role } });
                }}
              >
                <>
                  <Plus size={20} weight="bold" className="me-1" /> Add New Admin
                </>
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
