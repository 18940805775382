import React from 'react';
import OutOfServiceImg from './../assets/out-of-service.svg';
import { Heading1, Heading2 } from '../components/atoms/typography/typography';

export default function OutOfService() {
  return (
    <div className="container">
      <div className="row justify-content-center text-center mt-5 p-4">
        <img src={OutOfServiceImg} width={100} height={400} className="mb-5" />
        <Heading1>We are updating our system</Heading1>
        <Heading1>Please reach out at contact@voizo.ai for help.</Heading1>
      </div>
    </div>
  );
}
