import React, { ReactElement } from 'react';
import { FontWeight, Heading1, Para } from '../../atoms/typography/typography';
import './styles.css';
import { DotsThreeVertical } from 'phosphor-react';

interface StatsCardProps {
  title: string;
  value: string;
  image: ReactElement;
}

export default function StatsCard({ title, value, image }: StatsCardProps) {
  return (
    <div className="d-flex no-white-spaces">
      <div className="stats-card d-flex">
        <div className="flex-grow-1">
          <div className="d-flex justify-content-between no-white-spaces">
            <Para className="text-nowrap">{title}</Para>
          </div>
          <Heading1 fontWeight={FontWeight.REGULAR}>{value}</Heading1>
        </div>
        <div className="d-flex stats-image">{image}</div>
      </div>
    </div>
  );
}
