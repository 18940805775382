import React, { useState } from 'react';
import { FontWeight, Heading2, Para } from '../../atoms/typography/typography';
import { LinkButton } from '../../molecules/buttons/buttons';
import { useParams } from 'react-router-dom';
import { JobAdminDto } from '../../../redux/states/jobs/jobs.dto';
import { SkeletonLoader } from '../skeleton-loader/skeletonLoader';
import { LoaderType } from '../../../models/ui.enum';
import { Trash } from 'phosphor-react';
import { AlertModal } from '../../molecules/modal/modal';
import NoInternet from '../../../assets/no-internet.svg';
import { useDispatch } from 'react-redux';
import { LOADING_OFF } from '../../../redux/states/ui/ui.actions';
import { showAlert } from '../../../utils/utils';
import { removeJobAdminAction } from '../../../redux/states/jobs/jobs.actions';

interface JobAdminListProps {
  admins: JobAdminDto[];
}

export default function JobAdminList({ admins }: JobAdminListProps) {
  const dispatch: any = useDispatch();
  const { jobId } = useParams();
  const [removeAdmin, setRemoveAdmin] = useState<boolean>(false);
  const [passAdminId, setPassAdminId] = useState<string>('');

  async function removeJobAdmin() {
    const data = {
      adminId: passAdminId
    };
    try {
      const res = await dispatch(removeJobAdminAction(jobId ?? '', data));
      if (res.status === 200) {
        window.location.reload();
      } else {
        showAlert(dispatch, res.message);
      }
    } catch (e) {
      console.log(e);
    }
  }

  function handleRemoveAdminAlert(adminId: string) {
    setRemoveAdmin(true);
    setPassAdminId(adminId);
  }

  return (
    <div className="table-wrapper">
      <div className="p-4">
        <Heading2>Admin List</Heading2>
      </div>
      <div className="table-responsive">
        <table className="table table-borderless text-center">
          <thead className={'table-header-area'}>
            <tr>
              <th scope="col" className="text-start ps-4">
                <Para textColor={'var(--textGray)'}>Name</Para>
              </th>
              <th scope="col" className="text-start">
                <Para textColor={'var(--textGray)'}>Email</Para>
              </th>
              <th scope="col">
                <Para textColor={'var(--textGray)'}>Status</Para>
              </th>
              <th scope="col">
                <Para textColor={'var(--textGray)'}>Action</Para>
              </th>
            </tr>
          </thead>
          <tbody>
            {admins?.map((admin: JobAdminDto, index: number) => {
              return (
                <tr className={'table-row cursor-unset'} key={index}>
                  <td>
                    <div className="d-flex">
                      <div className="ms-3 text-nowrap">
                        <Para fontWeight={FontWeight.SEMIBOLD} className="text-capitalize">
                          {admin?.name ?? 'N/A'}
                        </Para>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div className="d-flex">
                      <Para fontWeight={FontWeight.MEDIUM} textColor={'var(--textGray)'}>
                        {admin?.email ?? 'N/A'}
                      </Para>
                    </div>
                  </td>

                  <td>
                    <div className="d-flex justify-content-center">
                      <Para fontWeight={FontWeight.MEDIUM} textColor={'var(--textGray)'}>
                        {admin?.inActive ? '--' : 'Active'}
                      </Para>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-center">
                      <Para fontWeight={FontWeight.MEDIUM} textColor={'var(--basicRed)'}>
                        <Trash
                          size={24}
                          role="button"
                          onClick={() => handleRemoveAdminAlert(admin?.adminId)}
                        />
                      </Para>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <AlertModal
        active={removeAdmin}
        primaryBtnTitle={'Ok'}
        onPrimaryBtnClick={() => {
          removeJobAdmin();
        }}
        onClose={() => {
          setRemoveAdmin(false);
        }}
      >
        <div className="text-center">
          <img src={NoInternet} className="mb-3" />
          <Heading2 className="mb-2">Are you sure you want to remove admin?</Heading2>
        </div>
      </AlertModal>
    </div>
  );
}
