import React, { Fragment, useEffect, useState } from 'react';
import { Heading2, Para } from '../../../../components/atoms/typography/typography';
import { PrimaryButton, SecondaryButton } from '../../../../components/molecules/buttons/buttons';
import { ArrowClockwise, CalendarCheck, CalendarX, Plus, UsersThree } from 'phosphor-react';
import CandidateList from '../../../../components/organism/candidate-list/candidateList';
import StatsCard from '../../../../components/molecules/stats-card/statsCard';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  exportCandidateListAction,
  getJobDetailAction,
  getJobStatsAction,
  updateCandidateInterviewAction
} from '../../../../redux/states/jobs/jobs.actions';
import { useSelector } from 'react-redux';
import Loader from '../../../../components/molecules/loader/loader';
import NoData from './../../../../assets/no-data.svg';
import './styles.css';
import { SkeletonLoader } from '../../../../components/organism/skeleton-loader/skeletonLoader';
import { InterviewStatusType, InterviewUpdateType, LoaderType } from '../../../../models/ui.enum';
import { NoDataComponent } from '../../../../components/organism/no-data-component/noDataComponent';
import { convertBinaryToXlsx, formatDate, showAlert } from '../../../../utils/utils';
import CandidateListNew from '../../../../components/organism/candidate-list/candidate-list-new';
import { CandidateDto, ScoreDto } from '../../../../redux/states/jobs/jobs.dto';
import SearchIcon from '../../../../assets/search.svg';
import { PrimaryTextfield } from '../../../../components/molecules/textfield/textfield';
import { LOADING_OFF, LOADING_ON } from '../../../../redux/states/ui/ui.actions';
import { toast } from 'react-toastify';
import { AlertModal } from '../../../../components/molecules/modal/modal';
import NoInternet from '../../../../assets/no-internet.svg';
import { getLoaderValue } from '../../../../redux/states/ui/ui.services';

export const JobDetails = () => {
  const navigate = useNavigate();

  const dispatch: any = useDispatch();
  const { jobId } = useParams();
  const { data: job, loading: loading } = useSelector(
    (jobDetailState: any) => jobDetailState?.job?.jobDetails
  );
  const { data: jobStats, loading: jobStatsLoading } = useSelector(
    (jobStatsState: any) => jobStatsState?.job?.jobStats
  );
  const [candidateList, setCandidateList] = useState<CandidateDto[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [retryCandidate, setRetryCandidate] = useState<string[]>([]);
  const [isRetryModalActive, setRetryModalActive] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState('completed');
  const [matchingCandidates, setMatchingCandidates] = useState(candidateList);
  const [selectedCandidates, setSelectedCandidates] = useState<any>([]);
  const isLoading = useSelector(getLoaderValue);

  function handleCandidateListStatus(status: InterviewStatusType) {
    let candidateStatusCheck;

    if (status === InterviewStatusType.INVITED) {
      candidateStatusCheck = job?.candidateDetails?.filter(
        (candidate: CandidateDto) =>
          candidate?.status === InterviewStatusType.INVITED ||
          candidate?.status === InterviewStatusType.INPROGRESS
      );
    } else {
      candidateStatusCheck = job?.candidateDetails?.filter(
        (candidate: CandidateDto) => candidate?.status === status
      );
    }

    setCandidateList(candidateStatusCheck);

    if (status === InterviewStatusType.COMPLETED) {
      setActiveTab('completed');
    } else if (status === InterviewStatusType.INVITED) {
      setActiveTab('invited');
    } else {
      setActiveTab('expired');
    }
  }

  async function handleExportData() {
    try {
      const res = await dispatch(exportCandidateListAction(jobId ?? ''));
      convertBinaryToXlsx(res, job?.jobDetails?.role);
    } catch (error) {
      console.log(error);
    }
  }

  function selectedRetryReinviteCandidates(data: any) {
    setSelectedCandidates(data);
  }

  async function updateCandidateInterview(candidateId: string[], action: InterviewUpdateType) {
    dispatch({ type: LOADING_ON });
    const data = {
      candidateId,
      action
    };
    try {
      const res = await dispatch(updateCandidateInterviewAction(jobId ?? '', data));
      dispatch({ type: LOADING_OFF });
      if (res.status === 200) {
        toast.success(
          `${
            action == InterviewUpdateType.REINVITE_INTERVIEW ? 'Reinvite ' : 'Retry'
          } link for this interview has been sent to the candidate successfully`
        );
        dispatch(getJobDetailAction(jobId ?? ''));
      } else {
        showAlert(dispatch, res.message);
      }
    } catch (e) {
      dispatch({ type: LOADING_OFF });
      console.log(e);
    }
  }

  useEffect(() => {
    dispatch(getJobDetailAction(jobId ?? ''));
    dispatch(getJobStatsAction(jobId ?? ''));
    handleCandidateListStatus(InterviewStatusType.INVITED);
  }, []);

  function onAddCandidateClick() {
    navigate(`/add-candidate/${jobId}`, { state: { role: job?.jobDetails?.role } });
  }

  function checkJobStatus() {
    let today = new Date();
    let startDate = new Date(job?.jobDetails?.startDate.substring(0, 10));

    if (today < startDate) {
      return false;
    } else {
      return true;
    }
  }
  let isJobStarted = checkJobStatus();

  function showJobStatus() {
    if (!job?.jobDetails?.inActive && !isJobStarted) {
      return (
        <span className="interview-in-future text-center">
          ● Interview starts from {formatDate(job?.jobDetails?.startDate)}
        </span>
      );
    } else if (!job?.jobDetails?.inActive && isJobStarted) {
      return (
        <span className="interview-active">
          {'● ' +
            formatDate(job?.jobDetails?.startDate) +
            ' - ' +
            formatDate(job?.jobDetails?.endDate)}
        </span>
      );
    } else {
      return <span className="interview-expired">●</span>;
    }
  }

  useEffect(() => {
    const filteredList = candidateList?.filter(
      (candidate) =>
        candidate?.candidateName?.toLowerCase().includes(searchText.toLowerCase()) ||
        candidate?.candidateEmail?.toLowerCase().includes(searchText.toLowerCase())
    );
    setMatchingCandidates(filteredList);
  }, [candidateList, searchText]);

  return (
    <Fragment>
      <div className="px-5 py-4">
        <div className="row">
          <div className="col-md-7">
            <div>
              <div className="mb-4 no-white-space">{showJobStatus()}</div>
              <h2> {job?.jobDetails?.role}</h2>
            </div>

            <div className="d-flex no-white-space flex-wrap">
              {job?.jobDetails?.jd?.attributes?.map((attribute: any, index1: number) => (
                <span className="jd-attributes" key={index1}>
                  {attribute?.attribute}
                </span>
              ))}
            </div>
          </div>
          <div className="col-md-5">
            <div className="d-flex flex-wrap mt-4 justify-content-md-end justify-content-start">
              <div className=" mb-2">
                <SecondaryButton
                  className="no-white-space"
                  isDisabled={job?.jobDetails?.inActive}
                  handleBtnClick={() => {
                    navigate(`/job-admins/${jobId}`, {
                      state: { role: job?.jobDetails?.role }
                    });
                  }}
                >
                  Admin List
                </SecondaryButton>
              </div>
            </div>
          </div>
        </div>

        <div className="row my-5">
          <div className="col-lg mb-2">
            <StatsCard
              title={'Total Candidates'}
              value={jobStats?.totalCandidates}
              image={<UsersThree size={32} weight="bold" color="var(--primary)" />}
            />
          </div>
          <div className="col-lg mb-2">
            <StatsCard
              title={'Completed Interview'}
              value={jobStats?.completed}
              image={<CalendarCheck size={32} weight="bold" color="var(--primary)" />}
            />
          </div>
          <div className="col-lg mb-2">
            <StatsCard
              title={'Expired Interview'}
              value={jobStats?.expired}
              image={<CalendarX size={32} weight="bold" color="var(--primary)" />}
            />
          </div>
        </div>
        <div>
          {loading || isLoading ? (
            <SkeletonLoader cardType={LoaderType.TILE} />
          ) : job?.candidateDetails && job?.candidateDetails.length ? (
            // <CandidateList candidates={job?.candidateDetails} jobDetails={job?.jobDetails} />
            <div className="">
              <div>
                <Heading2>Candidate List</Heading2>
              </div>
              <div className="row mt-4 justify-content-between">
                <div className="col-md-4">
                  <div className="input-container no-white-space">
                    <img src={SearchIcon} className="image" />
                    <PrimaryTextfield
                      onChange={(value) => setSearchText(value)}
                      placeholder="Search by Name or Email"
                      value={searchText}
                      fieldName=""
                      type="search"
                      class="inputField no-white-space"
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="d-flex justify-content-md-end justify-content-start mt-md-2 mt-0 flex-wrap">
                    {candidateList?.length != 0 && (
                      <SecondaryButton
                        className={`no-white-space me-2 mb-3 ${
                          activeTab === 'expired' ? 'd-none' : ''
                        }`}
                        isDisabled={job?.jobDetails?.inActive}
                        handleBtnClick={() => {
                          if (activeTab === 'completed') {
                            if (selectedCandidates?.length != 0) {
                              setRetryCandidate(selectedCandidates);
                              setRetryModalActive(true);
                            } else {
                              showAlert(dispatch, 'Please select at least one candidate');
                              return;
                            }
                          } else {
                            const action: any =
                              activeTab === 'invited' ? InterviewUpdateType.REINVITE_INTERVIEW : '';
                            if (selectedCandidates?.length != 0) {
                              updateCandidateInterview(selectedCandidates, action);
                            } else {
                              showAlert(dispatch, 'Please select at least one candidate');
                              return;
                            }
                          }
                        }}
                      >
                        <>
                          <ArrowClockwise size={16} weight="bold" className="me-2" />{' '}
                          {activeTab === 'completed' ? 'Retry' : 'Reinvite'}
                        </>
                      </SecondaryButton>
                    )}
                    <SecondaryButton
                      handleBtnClick={handleExportData}
                      // isDisabled={matchingCandidates.length === 0}
                      className="me-2 mb-3"
                    >
                      Export
                    </SecondaryButton>
                    <div>
                      <PrimaryButton
                        className="justify-content-center no-white-space mb-3"
                        isDisabled={job?.jobDetails?.inActive}
                        handleBtnClick={() => {
                          navigate(`/add-candidate/${jobId}`, {
                            state: { role: job?.jobDetails?.role }
                          });
                        }}
                      >
                        <>
                          <Plus size={20} weight="bold" className="" /> Add Candidate
                        </>
                      </PrimaryButton>
                    </div>
                  </div>
                </div>
              </div>
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button
                    data-bs-toggle="tab"
                    type="button"
                    role="tab"
                    aria-selected="false"
                    className={`nav-link ${activeTab === 'invited' ? 'active' : ''}`}
                    onClick={() => {
                      handleCandidateListStatus(InterviewStatusType.INVITED);
                    }}
                  >
                    Invited
                  </button>
                  <button
                    data-bs-toggle="tab"
                    type="button"
                    role="tab"
                    aria-selected="true"
                    className={`nav-link ${activeTab === 'completed' ? 'active' : ''}`}
                    onClick={() => {
                      handleCandidateListStatus(InterviewStatusType.COMPLETED);
                    }}
                  >
                    Completed
                  </button>
                  <button
                    data-bs-toggle="tab"
                    type="button"
                    role="tab"
                    aria-selected="false"
                    className={`nav-link ${activeTab === 'expired' ? 'active' : ''}`}
                    onClick={() => {
                      handleCandidateListStatus(InterviewStatusType.EXPIRED);
                    }}
                  >
                    Expired
                  </button>
                </div>
              </nav>
              <CandidateListNew
                completedInterviews={handleCandidateListStatus}
                jobDetails={job?.jobDetails}
                matchingCandidates={matchingCandidates}
                checkedCandidates={selectedRetryReinviteCandidates}
              />
            </div>
          ) : (
            <NoDataComponent
              image={NoData}
              text="Add your first Candidate here"
              ctaBtnText="Add New Candidate"
              onBtnClick={onAddCandidateClick}
              isDisabled={job?.jobDetails?.inActive ? true : false}
            />
          )}
        </div>
      </div>

      <AlertModal
        active={isRetryModalActive}
        primaryBtnTitle={'Ok'}
        onPrimaryBtnClick={() => {
          updateCandidateInterview(retryCandidate, InterviewUpdateType.RETRY_INTERVIEW);
          setRetryModalActive(false);
        }}
        onClose={() => {
          setRetryModalActive(false);
        }}
      >
        <div className="text-center">
          <img src={NoInternet} className="mb-3" />
          <Heading2 className="mb-2">Do you want candidate to retry this interview?</Heading2>
          <Para textColor="var(--textGray)" className="mb-4">
            By proceeding with this action, all scores will be reset, and a new interview will
            commence
          </Para>
        </div>
      </AlertModal>
    </Fragment>
  );
};
