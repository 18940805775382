// export const baseUrl = 'https://reqres.in/api';

//jobs
export const jobList = 'api/employer/jobs';

export const jobDetail = 'api/employer/job';

export const jobStats = 'api/employer/job-stats';

export const addJob = 'api/employer/job';

export const jdUpload = 'api/employer/jd-upload';

export const addCandidateResume = 'api/employer/resume-upload';

export const addCandidate = 'api/employer/add-candidate';

export const getVideo = 'api/employer/get-video-from-s3';

// hiring managers
export const hiringManagerList = 'api/employer/admin';

export const addHiringManager = 'api/employer/admin';

export const user = 'api/employer';

export const validateAdmin = 'api/employer/validate-admin';

export const updateHiringManager = 'api/employer/admin';

export const addAdmin = 'api/employer/add-job-admin';

export const jobAdminList = 'api/employer/view-job-admin';

export const exportCandidateList = 'api/employer/export-interview-data';

export const updateCandidateInterview = 'api/employer/update-candidate';

export const removeJobAdmin = 'api/employer/update-job-admin';
