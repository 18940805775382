import React, { useState } from 'react';
import { FontWeight, Heading1, Para } from '../../../../components/atoms/typography/typography';
import { PrimaryTextfield } from '../../../../components/molecules/textfield/textfield';
import { PrimaryButton, SecondaryButton } from '../../../../components/molecules/buttons/buttons';
import { useLocation, useNavigate } from 'react-router-dom';
import { ButtonType } from '../../../../models/ui.enum';
import { useDispatch } from 'react-redux';
import {
  addHiringManagerAction,
  updateHiringManagerAction
} from '../../../../redux/states/hiring-mangers/hiringManagers.actions';
import { toast } from 'react-toastify';
import { InfoModal } from '../../../../components/molecules/modal/modal';
import { useSelector } from 'react-redux';
import { getLoaderValue } from '../../../../redux/states/ui/ui.services';
import { LOADING_OFF, LOADING_ON } from '../../../../redux/states/ui/ui.actions';
import { showAlert } from '../../../../utils/utils';

function AddHiringManagers() {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const { state } = useLocation();
  const { hmname, hmInActive, id, hmemail } = state;

  const [inActive, setInActive] = useState(hmInActive);
  const [name, setAdminName] = useState(hmname);
  const [email, setAdminEmail] = useState(hmemail);
  const isLoading = useSelector(getLoaderValue);

  async function uploadHiringManager() {
    const data = {
      adminEmail: email,
      adminName: name
    };
    try {
      const res = await dispatch(addHiringManagerAction(data));
      dispatch({ type: LOADING_OFF });
      if (res.status === 200) {
        navigate(-1);
      } else {
        showAlert(dispatch, res.message);
      }
    } catch (e) {
      dispatch({ type: LOADING_OFF });
      showAlert(dispatch, 'Something went wrong! Please try again.');
    }
  }

  async function updateHiringManager() {
    const data = {
      inActive: inActive,
      name: name
    };
    try {
      await dispatch(updateHiringManagerAction(id, data));
      dispatch({ type: LOADING_OFF });
      navigate(-1);
    } catch (e) {
      dispatch({ type: LOADING_OFF });
      showAlert(dispatch, 'Something went wrong! Please try again.');
    }
  }

  function handleSubmit() {
    dispatch({ type: LOADING_ON });
    if (id) {
      updateHiringManager();
    } else {
      uploadHiringManager();
    }
  }

  function radioHandler() {
    if (inActive) {
      setInActive(false);
    } else {
      setInActive(true);
    }
  }

  return (
    <div className="p-5">
      <form
        onSubmit={(e) => {
          e?.preventDefault();
          handleSubmit();
        }}
      >
        <div className="mb-5">
          <Heading1 fontWeight={FontWeight.REGULAR}>Add Hiring Manager</Heading1>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-5">
            <PrimaryTextfield
              onChange={(value) => {
                setAdminName(value);
              }}
              label="Name"
              placeholder="Enter the name"
              value={name}
              fieldName="name"
              type="text"
              required
            />

            {id ? (
              <div className="mb-4">
                <Para>Status : </Para>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inActive"
                    id="true"
                    value="true"
                    onChange={radioHandler}
                    checked={inActive}
                  />
                  <label className="form-check-label">Inactive</label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="false"
                    name="inActive"
                    value="false"
                    onChange={radioHandler}
                    checked={!inActive}
                  />
                  <label className="form-check-label">Active</label>
                </div>
              </div>
            ) : (
              <PrimaryTextfield
                onChange={(value) => {
                  setAdminEmail(value);
                }}
                label="Email"
                placeholder="Enter the email"
                value={email}
                fieldName="email"
                type="email"
                required
              />
            )}

            <div className="row">
              <div className="col">
                <SecondaryButton
                  className="w-100"
                  handleBtnClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </SecondaryButton>
              </div>
              <div className="col">
                <PrimaryButton
                  className="w-100"
                  handleBtnClick={() => {}}
                  type={ButtonType.SUBMIT}
                  isLoading={isLoading}
                  loadingText="Please wait"
                >
                  Save
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddHiringManagers;
