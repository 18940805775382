import React from 'react';

export function breakLines(inputString: string) {
  const lines = inputString.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index < inputString?.split('\n').length - 1 ? (
        <div style={{ display: 'block', height: '8px' }}></div>
      ) : null}
    </React.Fragment>
  ));
  return <div>{lines}</div>;
}
